//@import "bootstrap/bootstrap";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
	font-weight: 300;
}

body {
  background-color: #EEEEEE;
}

.topdivider{
  margin-top: 5em;
  background-color: red;
}

#passwordgrid {
  padding-bottom: 10px;
}

.cardplaceholder {
  border: 1px solid #eee;
  height: 75px;
}

.connectedSortable{
  padding-bottom: 50px;
}

#sharepopup {
   display: none;
}

.header.item  i img {
  padding-right: 5px;
}

/** Hide the class is the screen is too small */
@media screen and (max-width: 480px) {
  .hideSmallScreen {
    display: none !important;
  }
}

/** Show the class is the screen is too small */
@media screen and (min-width: 480px) {
  .showSmallScreen {
    display: none !important;
  }
}

.headerfloatright {
  float: right;
}


.drop-area{
  width:300px;
  height:50px;
  border:2px dashed #CCC;
  text-align: center;
  padding:10px;
  cursor:pointer;
}

#thumbnail img{
  width:150px;
}

canvas{
  border:1px solid red;
}